@font-face {
  font-family: 'Mont';
  font-display: swap;
  src: local('Mont Heavy'), local('Mont-Heavy'),
  url('/fonts/mont/Mont-Heavy.woff2') format('woff2'),
  url('/fonts/mont/Mont-Heavy.woff') format('woff'),
  url('/fonts/mont/Mont-Heavy.otf') format('otf');
  font-weight: 800;
  font-style: normal;

}

@font-face {
  font-family: 'Mont';
  font-display: swap;
  src: local('Mont Bold'), local('Mont-Bold'),
  url('/fonts/mont/Mont-Bold.woff2') format('woff2'),
  url('/fonts/mont/Mont-Bold.woff') format('woff'),
  url('/fonts/mont/Mont-Bold.otf') format('otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Mont';
  font-display: swap;
  src: local('Mont SemiBold'), local('Mont-SemiBold'),
  url('/fonts/mont/Mont-SemiBold.woff2') format('woff2'),
  url('/fonts/mont/Mont-SemiBold.woff') format('woff'),
  url('/fonts/mont/Mont-SemiBold.otf') format('otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Mont';
  font-display: swap;
  src: local('Mont Regular'), local('Mont-Regular'),
  url('/fonts/mont/Mont-Regular.woff2') format('woff2'),
  url('/fonts/mont/Mont-Regular.woff') format('woff'),
  url('/fonts/mont/Mont-Regular.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Mont';
  font-display: swap;
  src: local('Mont Light'), local('Mont-Light'),
  url('/fonts/mont/Mont-Light.woff2') format('woff2'),
  url('/fonts/mont/Mont-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/montserrat/montserrat-v24-latin-800.eot');
  src: local(''),
  url('/fonts/montserrat/montserrat-v24-latin-800.woff2') format('woff2'),
  url('/fonts/montserrat/montserrat-v24-latin-800.woff') format('woff')
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/montserrat/montserrat-v24-latin-700.eot');
  src: local(''),
  url('/fonts/montserrat/montserrat-v24-latin-700.woff2') format('woff2'),
  url('/fonts/montserrat/montserrat-v24-latin-700.woff') format('woff')
}


@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/lato/lato-v23-latin-regular.eot');
  src: local(''),
  url('/fonts/lato/lato-v23-latin-regular.woff2') format('woff2'),
  url('/fonts/lato/lato-v23-latin-regular.woff') format('woff')
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/lato/lato-v23-latin-italic.eot');
  src: local(''),
  url('/fonts/lato/lato-v23-latin-italic.woff2') format('woff2'),
  url('/fonts/lato/lato-v23-latin-italic.woff') format('woff')
}