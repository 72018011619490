.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 320px;
  padding: 18px;
  position: fixed;
  right: 0;
  bottom: 80px;
  z-index: 100;
}

.container {
  position: relative;
  height: 100%;
  min-height: 67px;
  display: flex;
  align-items: center;
  width: 100%;
}

.notify {
  padding: 8px 8px 0;
  width: 100%;
  height: 100%;
  min-height: 80px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 2px 18px rgba(50, 52, 66, 0.05);
  align-items: center;
  flex-direction: column-reverse;
}

.boxIcon {
  min-width: 48px;
  padding: 0 24px 0 10px;
  margin-right: 12px;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--blue-grey-color-50);
}

.loading {
  width: 0%;
  height: 5px;
  background-size: 353px 5px;
  border-radius: 12px;
  animation: loading 14.5s ease-in-out forwards;
  display: flex;
  justify-self: flex-start;
}

.text {
  font-size: 14px;
  line-height: 22px;
}

@keyframes loading {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}

.error {
  background: #e74905;
  background: linear-gradient(to left, #e74905);
}

.info {
  background: #4fbe68;
  background: linear-gradient(to left, #4fbe68);
}

.closeBtn {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .root {
    min-width: 460px;
  }
}
