* {
  // scroll-behavior: auto !important;
  box-sizing: border-box;
  line-height: 1.3em;
  font-family: 'Mont', serif;
}

*,
:after,
:before {
  box-sizing: border-box;
}

*,
:after,
:before,
body,
html {
  margin: 0;
  padding: 0;
}

header,
img {
  display: block;
}

div,
span {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  vertical-align: baseline;
}

html {
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

p {
  font-size: 18px;
  color: #000;
  line-height: 36px;
  font-weight: 400;
}

img {
  width: auto;
  height: auto;
}

a {
  text-decoration: none;
}

a:link {
  scroll-behavior: auto !important;
}

button {
  cursor: pointer;
  background: 0 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  text-shadow: none;
}

button:focus {
  outline: 0;
}

body {
  background: #fff;
  font-size: 18px;
  line-height: 36px;
  font-weight: 400;
  color: #181818;
  -webkit-font-smoothing: antialiased;

  @media (max-width: 729px) {
    margin-bottom: 54px;
  }
}

body.has-modal {
  @media (max-width: 1023px) {
    overflow: hidden;
  }
}

ul {
  list-style: none;
}

[role='button'],
button {
  cursor: pointer;
  background: 0 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  text-shadow: none;
}

[role='button']:focus,
button:focus {
  outline: 0;
}

//NProgress bar color
#nprogress .bar {
  background-color: var(--vitaliv-green-color) !important;
  height: 3px !important;
}

strong {
  font-weight: 700;
}

// Move to Cart Slider component when it will be ready

.cartSectionWrapper {
  min-height: 700px;
}

.cart-text {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 100px;
  padding: 0 30px;
  font-size: 18px;
  line-height: 200%;
  font-weight: 600;
  letter-spacing: 0.015em;
  &__link {
    color: var(--brand-1);
    text-decoration: underline;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    margin-top: 40px;
    margin-bottom: 60px;
  }
}

.containerPage {
  background-color: #efefef;
}

.containerTG {
  background-color: #f5f5f5;
}

.pageJoints {
  background-color: #fafafa;
}

.containerJoints {
  max-width: 1160px;
  margin: 0 auto;
  padding: 96px 20px;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 60px 14px 60px 16px;
  }
}

.containerSite {
  max-width: 1240px;
  margin: 0 auto;
  width: 100%;
}
