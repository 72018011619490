:root {
  --vitaliv-orange-color: #ff6c0e;
  --vitaliv-yellow-color: #ffc600;
  --vitaliv-green-color: #4faf4d;
  --vitaliv-light-green-color: #789c4a;
  --vitaliv-blue-color: #002169;
  --vitaliv-purple-color: #490e67;
  --vitaliv-cyan-color: #00829b;
  --danger-color: #e56966;
  --blue-grey-color-0: #f3f8f8;
  --blue-grey-color-50: #e6f1f1;
  --blue-grey-color-150: #a0b6b6;
  --blue-grey-color-200: #73818a;
  --blue-grey-color-300: #657c7c;

  --brand-1: #008bc8;
  --brand-2: #ffc700;
  --brand-3: #e5f4f6;
  --brand-4: #ffebcd;
  --vitaliv: #4faf4d;
  --vitaliv-light: #a1d3a2;
  --accent: #f7d950;
  --black: #000;
  --white: #fff;
  --gray: #d2d2d2;
  --dark-gray: #657c7c;
  --light-gray: #a0b6b6;
  --bright: #f3f8f8;
  --border: #e0e0e0;
  --separator: #e6f1f1;
  --error: #f00;
  --error-soft: #e56966;
  --bg: #f3f8f8;
  --active: #ff6c0e;
  --gold: #cc8e00;
  --bg-xmas: #002169;
  --color-xmas: #ffcb56;
  --text-base: #000;
  --text-light: var(--blue-grey-color-200);
  --text-mid: #181818;
  --text-pale: #657c7c;
  --good-shadow: 0 16px 32px rgba(160, 182, 182, 0.25);

  --button-radius: 50px;
}
